html,
body {
    overscroll-behavior: none;
}

a {
    @apply text-blue-500 underline;
}

/** mobile **/
@media (min-width: 200px) and (max-width: 480px) {
}

.form-input {
    @apply w-full py-2 px-3 text-sm leading-6 border border-gray-300 rounded-md shadow-sm;
}

.dropzone {
    min-height: 10rem;
}


/** light mode **/
body[data-theme="light"] {
    @apply bg-gray-200;

    & .form-select:hover {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%236b7280' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    }

    & div.bg-gray-100 div.toggle span.not-toggled {
        @apply bg-gray-300;
    }

    & .form-input {
        &:focus {
            @apply shadow-outline-indigo;
        }
    }
}

/** night mode **/
body[data-theme="dark"] {
    @apply bg-gray-900;

    & .form-select:hover {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%23f3f4f6' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    }

    & .form-input,
    & .form-select {
        @apply text-sm text-white bg-gray-700 border border-gray-900 shadow;
    }
}

body[data-theme='light'] select.form-select.has--placeholder,
body[data-theme='dark'] select.form-select.has--placeholder,
select.form-select.has--placeholder {
    color: #9fa6b2;
}

select.form-select {
    @apply text-sm;
}

.form-input:disabled {
    @apply opacity-50 cursor-not-allowed;
}
