@import 'tailwindcss/base';

@import 'tailwindcss/components';
/* @import 'components.css'; */
@import 'buttons.css';

@import 'tailwindcss/utilities';
/* @import 'utilities.css'; */

@import 'main.css';
